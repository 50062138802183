<template>
    <div class="container-fluid pl-5 pr-5 content-container">
        <div class="row pb-3">
            <div class="col-12 shadow bg-white pt-3">
                <p class="heading">Reports</p>
                <hr>
                <div class="row pl-3 pr-3 mb-3">
                    <div class="col-sm-6">
                        <div class="row pr-2">
                            <div class="col-sm-12 shadow border rounded pt-3">
                                <div class="heading text-muted">Today</div>
                                <hr>
                                <daily-report></daily-report>
                            </div>

                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="row pl-2">
                            <div class="col-sm-12 shadow border rounded pt-3">
                                <div class="heading text-muted">Last 7 Days</div>
                                <hr>
                                <weekly-report></weekly-report>
                            </div>

                        </div>
                    </div>
                    <div class="col-sm-6 mt-3">
                        <div class="row pr-2">
                            <div class="col-sm-12 shadow border rounded pt-3">
                                <div class="heading text-muted">Last 30 Days</div>
                                <hr>
                                <monthly-report></monthly-report>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="row pl-3 pr-3 mb-3">

                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import DailyReport from './Daily';
    import WeeklyReport from './Weekly.vue';
    import MonthlyReport from './Monthly.vue';
    export default{
        components: {DailyReport, WeeklyReport, MonthlyReport}
    }
</script>